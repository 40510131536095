.comment {
  margin-top: -1.3%;
  padding-left: 4px;
  font-size: 70%;
  margin-right: 6px;
}

.test-value {
  width: 11%;
}

.title-underline {
  width: 5%;
}

.unit {
  font-size: 64%;
  margin-bottom: -4px;
}
