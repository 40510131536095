.questions-container {
  font-size: 58%;
  border-color: #1d7bbe;
  border: 2px solid;
}

.question-container {
  border-color: #1d7bbe;
  border-width: 1px;
}

.question-name {
  width: 128px;
  min-height: 26px;
}

.answer {
  width: 128px;
}
